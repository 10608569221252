import { Link, graphql, PageProps } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import DefaultSiteLayout from '../../components/default-site-layout'

type AdrIndexQueryDataType = {
  site: {
    siteMetadata: {
      siteName: string
    }
  }
  allMdx: {
    edges: Array<{
      node: {
        id: string
        body: string
        excerpt: string
        fields: {
          slug?: string
        }
        frontmatter: {
          title: string
          date?: string
          draft?: boolean
          noindex?: boolean
        }
      }
    }>
  }
}
type AdrIndexContextType = { slug: string }
type AdrIndexProps = PageProps<AdrIndexQueryDataType, AdrIndexContextType>

export default function AdrIndexPage({ data }: AdrIndexProps) {
  const { edges: posts } = data.allMdx
  // const { title: siteName } = data.site.siteMetadata

  return (
    <DefaultSiteLayout>
      <Helmet>
        <title>ADR Index - notakaos.com</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <main className="">
        <div className="prose prose-blue">
          <Link to={'/'}>Top</Link>
        </div>
        <h1 className="mb-8 font-mono text-4xl font-bold">ADR Index</h1>
        <section className="prose">
          <ul className="">
            {posts.map(({ node }: { node: any }) => {
              const { title, date, draft } = node.frontmatter
              return (
                <li key={node.id}>
                  <Link to={node.fields.slug} className="">
                    {draft ? <span className="mr-1 text-sm text-gray-400">(draft)</span> : ''}
                    {title}
                    <span className="ml-1 text-xs text-gray-400">({date})</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </section>
      </main>
    </DefaultSiteLayout>
  )
}

export const pageQuery = graphql`
  query AdrIndex {
    __typename
    site {
      siteMetadata {
        siteName
      }
    }
    allMdx(
      filter: { frontmatter: { tags: { in: "adr" } }, fields: { draft: { eq: false } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 1000
    ) {
      totalCount
      edges {
        node {
          id
          body
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            draft
            noindex
          }
        }
      }
    }
  }
`
